import React from "react";
import { Table } from "semantic-ui-react";
import { getInvoiceLineTypeDescription, InvoiceLineType } from "./configs";

export const NewInvoicesOverviewTable = ({ invoiceData, tariffs }) => {
	if (!tariffs) return null;

	let data = Array(InvoiceLineType.Last)
		.fill(0)
		.map((_, i) => {
			return {
				description: getInvoiceLineTypeDescription(i),
				count: Array(tariffs.length + 1).fill(0),
				totalAmount: Array(tariffs.length + 1).fill(0)
			};
		});

	let hasLines = Array(tariffs.length + 1).fill(false);

	for (let familyId in invoiceData) {
		let familyData = invoiceData[familyId];
		for (let line of familyData.lines) {
			if (!data[line.type]) {
				console.log("Unknown line type: ", line);
				continue;
			}

			data[line.type].count[line.tariffId] += 1;
			data[line.type].totalAmount[line.tariffId] += line.amount;
			hasLines[line.tariffId] = true;
		}
	}

	return (
		<>
			<h3>Overzicht</h3>
			<Table celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Omschrijving</Table.HeaderCell>
						{tariffs.map((tariff, i) => {
							if (!hasLines[i]) return null;

							return (
								<>
									<Table.HeaderCell collapsingl>{i}#</Table.HeaderCell>
									<Table.HeaderCell collapsing>{i}€</Table.HeaderCell>
								</>
							);
						})}
						{hasLines[tariffs.length] && (
							<>
								<Table.HeaderCell collapsing>Onb.#</Table.HeaderCell>
								<Table.HeaderCell collapsing>Onb.€</Table.HeaderCell>
							</>
						)}
						<Table.HeaderCell collapsing>Totaal #</Table.HeaderCell>
						<Table.HeaderCell collapsing>Totaal €</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{data.map((line, i) => {
						return (
							<Table.Row key={i}>
								<Table.Cell>{getInvoiceLineTypeDescription(i)}</Table.Cell>
								{tariffs.map((_, j) => {
									if (!hasLines[j]) return null;
									return (
										<>
											<Table.Cell collapsing>
												{line.count[j] || "-"}
											</Table.Cell>
											<Table.Cell collapsing>
												{line.totalAmount[j]
													? `€${line.totalAmount[j].toFixed(2)}`
													: "-"}
											</Table.Cell>
										</>
									);
								})}
								{hasLines[tariffs.length] && (
									<>
										<Table.Cell collapsing>
											{line.count[line.count.length - 1] || "-"}
										</Table.Cell>
										<Table.Cell collapsing>
											{line.totalAmount[line.totalAmount.length - 1]
												? `€${line.totalAmount[
														line.totalAmount.length - 1
												  ].toFixed(2)}`
												: "-"}
										</Table.Cell>
									</>
								)}
								<Table.Cell collapsing>
									{line.count.reduce((acc, count) => acc + count, 0)}
								</Table.Cell>
								<Table.Cell collapsing>
									{line.totalAmount.reduce((acc, amount) => acc + amount, 0)
										? `€${line.totalAmount
												.reduce((acc, amount) => acc + amount, 0)
												.toFixed(2)}`
										: "-"}
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
				<Table.Footer>
					<Table.Row>
						<Table.HeaderCell>
							<b>Totaal</b>
						</Table.HeaderCell>
						{tariffs.map((_, i) => {
							if (!hasLines[i]) return null;
							let totalSum = data.reduce((acc, line) => acc + line.totalAmount[i], 0);
							let totalCount = data.reduce((acc, line) => acc + line.count[i], 0);
							return (
								<>
									<Table.HeaderCell>
										<b>{totalCount || "-"}</b>
									</Table.HeaderCell>
									<Table.HeaderCell>
										<b>{totalSum ? `€${totalSum.toFixed(2)}` : "-"}</b>
									</Table.HeaderCell>
								</>
							);
						})}
						{hasLines[tariffs.length] && (
							<>
								<Table.HeaderCell>
									<b>
										{data.reduce(
											(acc, line) => acc + line.count[line.count.length - 1],
											0
										)}
									</b>
								</Table.HeaderCell>
								<Table.HeaderCell>
									<b>
										{data.reduce(
											(acc, line) =>
												acc + line.totalAmount[line.totalAmount.length - 1],
											0
										)
											? `€${data
													.reduce(
														(acc, line) =>
															acc +
															line.totalAmount[
																line.totalAmount.length - 1
															],
														0
													)
													.toFixed(2)}`
											: "-"}
									</b>
								</Table.HeaderCell>
							</>
						)}
						<Table.HeaderCell>
							<b>
								{data.reduce(
									(acc, line) =>
										acc + line.count.reduce((acc, count) => acc + count, 0),
									0
								)}
							</b>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<b>
								{data.reduce(
									(acc, line) =>
										acc +
										line.totalAmount.reduce((acc, amount) => acc + amount, 0),
									0
								)
									? `€${data
											.reduce(
												(acc, line) =>
													acc +
													line.totalAmount.reduce(
														(acc, amount) => acc + amount,
														0
													),
												0
											)
											.toFixed(2)}`
									: "-"}
							</b>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>
		</>
	);
};
