import firebase from "firebase/app";
import "firebase/firestore";
import { readFile } from "../../../reader";

const COLUMNIDX = {
	KLANTNUMMER: 0,
	TARIFF: 1,
	LAST: 2
};

const columns = [
	{ index: COLUMNIDX.KLANTNUMMER, required: true, upper: true },
	{ index: COLUMNIDX.TARIFF, required: true, upper: false }
];

export const importTariffData = (file, tariffs, onlyFamilies) => {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.onload = function (progressEvent) {
			const tariffData = {};

			const success = readFile(this.result, columns, reject, (lineArray) => {
				let familyId = lineArray[COLUMNIDX.KLANTNUMMER];
				if (familyId.substring(0, 2) !== "AN" && familyId.substring(0, 6) !== "TESTAN") {
					console.log("FamilyId incorrect: " + familyId);
					return;
				}

				let tariffId = Number(lineArray[COLUMNIDX.TARIFF]);
				if (!tariffs[tariffId]) {
					console.log("TariffId incorrect: " + tariffId);
					return;
				}

				if (onlyFamilies.length > 0 && !onlyFamilies.includes(familyId)) {
					// SKIP
					return;
				}

				tariffData[familyId] = tariffId;
			});

			if (!success) {
				reject("!success in importTariffData");
				return;
			}

			console.log(tariffData);

			resolve({ tariffData });
		};

		reader.readAsText(file);
	});
};
