import { useMemo } from "react";

import moment from "moment";
import { useVastePlanning } from "./useVastePlanning";
import { DienstSource, DienstStatus, SubscriptionStatus } from "../constants";
import { useAdjustments } from "./useAdjustments";
import { checkDienstenMonth } from "../helpers/checkDienstenMonth";

const parseDateString = (str) => moment(str, ["DD-MM-YY", "DD-MM-YYYY", "YYYY-MM-DD"]);

const getVasteDienstenForFamily = (
	familyId,
	vastePlanning,
	firstOfFromMonth,
	lastOfToMonth,
	adjustments
) => {
	const subscriptionsForFamily = vastePlanning.filter((vp) => vp.familyId === familyId);

	// Add diensten from subscriptions
	let familyDiensten = [];
	let duplicateCheck = {};
	for (let i = subscriptionsForFamily?.length - 1; i >= 0; i--) {
		const subscription = subscriptionsForFamily[i];

		let from = firstOfFromMonth.clone();
		let to = lastOfToMonth.clone();

		const startDateMoment = parseDateString(subscription.startDate);
		if (!startDateMoment.isValid()) {
			console.error("Invalid start date", subscription.startDate);
			continue;
		}

		if (startDateMoment.isAfter(lastOfToMonth)) {
			// Will start after this month has ended
			// console.log("Subscription starts after this month has ended", subscription);
			continue;
		} else if (startDateMoment.isAfter(firstOfFromMonth)) {
			// Will start this month
			from = startDateMoment.clone();
		} else {
			// Has started before this month, set to correct iso weekday
			let isoWeekDay = startDateMoment.isoWeekday();
			while (from.isoWeekday() !== isoWeekDay) {
				from.add(1, "day");
			}
		}

		const endDateMoment = parseDateString(subscription.endDate);
		if (endDateMoment.isValid()) {
			if (endDateMoment.isBefore(firstOfFromMonth)) {
				// Has ended before this month has started
				// console.log("Subscription has ended before this month has started", subscription);
				continue;
			} else if (endDateMoment.isBefore(lastOfToMonth)) {
				// Has ended this month
				to = endDateMoment;
			}
		}

		let dienstBase = {
			source: DienstSource.Subscription,
			familyId: subscription.familyId,
			subscriptionStatus: subscription.status,
			status:
				subscription.status === SubscriptionStatus.Confirmed
					? DienstStatus.Confirmed
					: DienstStatus.Open,
			startTime: subscription.startTime,
			endTime: subscription.endTime
		};

		for (let curdate = from.clone(); curdate.isSameOrBefore(to); curdate.add(1, "week")) {
			const oldId = `${curdate.format("YYYYMMDD")}_${familyId}_${
				subscription.nannyId || "NONANNY"
			}`;

			const id = `${curdate.format("YYYYMMDD")}_${familyId}_${
				subscription.nannyId || "NONANNY"
			}_${subscription.startTime}_${subscription.endTime}`;

			if (duplicateCheck[id]) {
				console.debug("Duplicate dienst found: ", id);
				continue;
			}

			duplicateCheck[id] = true;

			let dienst = {
				...dienstBase,
				id,
				oldId,
				date: curdate.format("YYYY-MM-DD"),
				displayDate: curdate.format("LL (dd)"),
				nannyId: subscription.nannyId
			};

			familyDiensten.push(dienst);
		}
	}

	// Apply adjustments
	const adjustmentsForFamily = adjustments.filter((adj) => adj.familyId === familyId);

	// Sort by updateTimestamp, from most recent to least recent
	let adjustmentsForFamilySorted = adjustmentsForFamily.sort((a, b) => {
		if (a.updateTimestamp && b.updateTimestamp) {
			return b.updateTimestamp - a.updateTimestamp;
		} else if (a.updateTimestamp) {
			return -1;
		} else if (b.updateTimestamp) {
			return 1;
		} else {
			return 0;
		}
	});

	for (let i = 0; i < adjustmentsForFamilySorted.length; i++) {
		const adjustment = adjustmentsForFamilySorted[i];

		let matchOnKeys = ["nannyId", "startTime", "endTime"];
		let maxMatchingKeys = 0;
		let matchingDienstIdx = -1;
		for (let j = 0; j < familyDiensten.length; j++) {
			const dienst = familyDiensten[j];
			if (dienst.date !== adjustment.date) continue;
			if (dienst.familyId !== adjustment.familyId) continue;

			if (dienst.adjustmentId) {
				console.log("Dienst already adjusted", dienst);
				continue;
			}

			let matchingKeys = 0;
			for (let k = 0; k < matchOnKeys.length; k++) {
				let key = matchOnKeys[k];
				if (dienst[key] === adjustment[key]) {
					matchingKeys++;
				}
			}

			if (matchingDienstIdx < 0 || matchingKeys > maxMatchingKeys) {
				maxMatchingKeys = matchingKeys;
				matchingDienstIdx = j;
			}

			if (maxMatchingKeys === matchOnKeys.length) {
				break;
			}
		}

		if (!familyId.includes("TEST") && matchingDienstIdx < 0) {
			console.error("Could not find matching dienst for adjustment", adjustment);
			console.error("Family diensten", familyDiensten);
			continue;
		}

		const { updateTimestamp, ...rest } = adjustment.adjustment;

		familyDiensten[matchingDienstIdx] = {
			...familyDiensten[matchingDienstIdx],
			adjustmentId: adjustment.id,
			...rest
		};

		if (updateTimestamp) {
			familyDiensten[matchingDienstIdx].updateTimestamp = updateTimestamp.toDate();
		}
	}

	return familyDiensten;
};

function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}

export const useVastePlanningDiensten = (familyId, fromMonthStr, toMonthStr) => {
	const vastePlanning = useVastePlanning({
		fromMonthStr,
		toMonthStr,
		onlyFuture: false
	});

	const { loading, adjustments } = useAdjustments(familyId, fromMonthStr, toMonthStr);

	const diensten = useMemo(() => {
		if (!vastePlanning) return [];
		if (!fromMonthStr) return [];
		if (!toMonthStr) return [];

		let fromMonth = moment(fromMonthStr, "YYYY_MM");
		if (!fromMonth.isValid()) return [];

		let toMonth = moment(toMonthStr, "YYYY_MM");
		if (!toMonth.isValid()) return [];

		if (!checkDienstenMonth(adjustments, fromMonthStr, toMonthStr)) {
			return [];
		}

		const firstOfFromMonth = fromMonth.startOf("month");
		const lastOfToMonth = toMonth.endOf("month");

		const families = familyId
			? [familyId]
			: vastePlanning.map((vp) => vp.familyId).filter(onlyUnique);
		let diensten = [];
		for (let i = 0; i < families.length; i++) {
			const familyId = families[i];
			if (!familyId) continue;

			const familyDiensten = getVasteDienstenForFamily(
				familyId,
				vastePlanning,
				firstOfFromMonth,
				lastOfToMonth,
				adjustments
			);

			diensten = diensten.concat(familyDiensten);
		}

		return diensten;
	}, [familyId, fromMonthStr, toMonthStr, vastePlanning, adjustments]);

	return { loading, diensten };
};
