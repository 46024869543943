import React, { useEffect, useMemo } from "react";
import { Form } from "semantic-ui-react";
import { useNanniesById } from "../../hooks/useNannies";
import { useActualDiensten } from "../../hooks/useActualDiensten";
import {
	DienstSource,
	DienstStatus,
	getDienstNannyName,
	getDienstSourceLabel,
	getDienstStatusLabelComponent,
	isCancelled
} from "../../constants";

export const DienstDropdown = ({
	label,
	familyId,
	value,
	onChange,
	source,
	fromMonthStr,
	toMonthStr,
	includeCancelled = false,
	includeReplaced = false,
	includeSubscriptionDiensten = true,
	includeDiensten = true
}) => {
	const nanniesByID = useNanniesById();

	// Vaste diensten voor family
	const { actualDiensten, loading } = useActualDiensten({
		familyId,
		fromMonthStr,
		toMonthStr,
		includeReplaced,
		includeDiensten,
		includeSubscriptionDiensten,
		onlyLocalChanges: true,
		includeCancelled
	});

	const dienstOptions = useMemo(() => {
		if (!actualDiensten) return [];

		let diensten = actualDiensten;
		if (typeof source !== "undefined") {
			diensten = actualDiensten.filter((d) => d.source === DienstSource.Subscription);
		}

		if (!includeCancelled) {
			diensten = diensten.filter((d) => !isCancelled(d.status));
		}

		return diensten.map((dienst) => {
			let text = `${dienst.displayDate}`;

			let nannyName = getDienstNannyName(dienst, nanniesByID);
			if (nannyName) {
				text = text + ` (${nannyName})`;
			}

			let extraContent = [];

			if (typeof source === "undefined") {
				extraContent.push(getDienstSourceLabel(dienst));
			}

			if (typeof source === "undefined" || source === DienstSource.Dienst) {
				extraContent.push(getDienstStatusLabelComponent(dienst.status));
			}

			let content = text;
			if (extraContent.length > 0) {
				content = (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between"
						}}
					>
						{text}
						<div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
							{extraContent}
						</div>
					</div>
				);
			}

			return {
				text: content,
				value: dienst,
				key: dienst.id
			};
		});
	}, [actualDiensten, includeCancelled, nanniesByID, source]);

	let useValue = useMemo(() => {
		if (!value) return null;
		if (typeof value === "object") return value;
		let option = dienstOptions.find((d) => d.value.id === value);
		if (option) return option.value;
		return null;
	}, [dienstOptions, value]);

	useEffect(() => {
		if (!dienstOptions) return;

		let dienst = dienstOptions.find((d) => d.value === useValue);
		if (dienst) return;

		if (dienstOptions.length > 0) {
			onChange(null, { value: dienstOptions[0].value });
		} else {
			onChange(null, { value: null });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dienstOptions]);

	return (
		<Form.Dropdown
			label={label || "Dienst"}
			loading={loading}
			disabled={loading}
			selection
			search
			options={dienstOptions}
			value={useValue}
			onChange={onChange}
		/>
	);
};
