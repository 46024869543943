import moment from "moment";

import { CreditStatus, CREDITNRMONTHSVALID, DATEFORMAT, tariffTypes } from "../../../constants";
import { readInvoiceInputFile } from "./readInvoiceInputFile";
import { getInvoiceForFamily } from "./getInvoiceForFamily";
import { removeCreditsForMonth } from "./removeCreditsForMonth";
import { getFamiliesForInvoices } from "./getFamiliesForInvoices";
import { applyInvoiceLine } from "./applyInvoiceLine";
import { addUnusedDaysToInvoice } from "./addUnusedDaysToInvoice";
import { setExpiredCredits } from "./setExpiredCredits";
import { addUnusedFlexToInvoice } from "./addUnusedFlexToInvoice";
import { finalizeInvoice } from "./finalizeInvoice";

const sortOnDateStr = (a, b) => {
	if (!a.dateStr && b.dateStr) return -1;
	if (!b.dateStr && a.dateStr) return 1;
	if (a.dateStr < b.dateStr) return -1;
	if (a.dateStr > b.dateStr) return 1;
	return 0;
};

const addNewCreditsForMonth = (credits, onlyFamilies, vastePlanningDiensten) => {
	for (let i = 0; i < vastePlanningDiensten.length; i++) {
		const { familyId, date } = vastePlanningDiensten[i];

		if (onlyFamilies.length > 0 && !onlyFamilies.includes(familyId)) {
			// SKIP
			continue;
		}

		if (!(familyId in credits)) {
			credits[familyId] = [];
		}

		const record = {
			dateStr: date,
			validUntilDateStr: moment(date, DATEFORMAT)
				.add(CREDITNRMONTHSVALID, "month")
				.format(DATEFORMAT),
			tariffId: 6, // todo
			status: CreditStatus.Available
		};

		credits[familyId].push(record);
	}

	// Make unique and sort chronologically
	for (const familyId in credits) {
		credits[familyId].sort(sortOnDateStr);
	}

	return credits;
};

export const importInvoiceDataWithVastePlanning = (
	file,
	month,
	tariffs,
	credits,
	subscriptions,
	onlyFamilies,
	vastePlanning,
	vastePlanningDiensten,
	tariffData
) => {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.onload = async function (progressEvent) {
			const monthMoment = moment(month, "YYYY_MM");

			let invoices = readInvoiceInputFile(this.result, reject, tariffs, onlyFamilies);
			if (!invoices) {
				reject("!success in importInvoiceData");
				return;
			}

			const lastMonthDateStr = monthMoment.clone().endOf("month").format(DATEFORMAT);
			const firstMonthDateStr = monthMoment.clone().startOf("month").format(DATEFORMAT);

			// Deep copy
			const newCredits = JSON.parse(JSON.stringify(credits));

			removeCreditsForMonth(newCredits, monthMoment, onlyFamilies);

			addNewCreditsForMonth(newCredits, onlyFamilies, vastePlanningDiensten);

			// Fill flex subscriptions
			const flexTariffs = tariffs
				.filter((tariff) => tariff.type === tariffTypes.Flex)
				.map((tariff) => tariff.tariffId);

			let flexSubscriptions = {};
			for (let familyId in tariffData) {
				if (flexTariffs.includes(tariffData[familyId])) {
					flexSubscriptions[familyId] = {
						tariffId: tariffData[familyId]
					};
				}
			}

			const allFamilies = getFamiliesForInvoices(
				invoices,
				newCredits,
				flexSubscriptions,
				onlyFamilies
			);

			for (let i = 0; i < allFamilies.length; i++) {
				const familyId = allFamilies[i];

				const invoice = getInvoiceForFamily(invoices, familyId, false);
				if (invoice) {
					// Ensure chronologically sorted invoice lines (important for credit use)
					invoice.lines.sort(sortOnDateStr);

					for (let linenr = 0; linenr < invoice.lines.length; linenr++) {
						// Fill tariffId
						if (tariffData[familyId]) {
							invoice.lines[linenr].tariffId = tariffData[familyId];
						} else if (newCredits[familyId]) {
							invoice.lines[linenr].tariffId = 6;
						} else {
							invoice.lines[linenr].tariffId = 5;
						}

						applyInvoiceLine(
							invoice.lines[linenr],
							familyId,
							tariffs,
							flexSubscriptions,
							newCredits,
							invoice,
							monthMoment
						);
					}
				}

				// Pay for remaining new credits that are not used
				addUnusedDaysToInvoice(
					invoices,
					familyId,
					newCredits,
					firstMonthDateStr,
					lastMonthDateStr,
					tariffs
				);

				// Set expired credits
				setExpiredCredits(familyId, newCredits, lastMonthDateStr);

				// Pay for unused flex days
				addUnusedFlexToInvoice(invoices, familyId, flexSubscriptions, tariffs, monthMoment);
			}

			// Final sort on all invoice lines and calculate totalAmount
			for (const familyId in invoices) {
				finalizeInvoice(invoices[familyId]);
			}

			console.log(invoices);

			resolve({ invoices, newCredits });
		};

		reader.readAsText(file);
	});
};
