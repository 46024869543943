import React from "react";
import { Segment, Button, Label, Message } from "semantic-ui-react";
import styles from "./FileImportForm.module.css";

const FileImportFormUncontrolled = (props) => {
	const [file, setFile] = React.useState(null);
	return <FileImportFormControlled {...props} file={file} setFile={setFile} />;
};

export const FileImportFormControlled = ({
	name,
	onSubmit,
	loading,
	error,
	success,
	errorMessage,
	basic,
	noHeader,
	file,
	setFile,
	disabled
}) => {
	const fileInputRef = React.useRef(null);

	const handleFileChange = () => {
		if (!fileInputRef.current) return;
		setFile(fileInputRef.current.files[0]);
	};

	let message = null;
	if (error) {
		message = (
			<Message error header={"Fout bij het importeren van " + name} content={errorMessage} />
		);
	} else if (success) {
		message = <Message success header={name + " importeren voltooid!"} />;
	}

	let content = (
		<Segment
			loading={loading}
			className={styles.content}
			basic={basic}
			style={basic ? { margin: 0 } : {}}
			disabled={disabled}
		>
			<Button
				className={styles.importButton}
				compact
				content={"Kies bestand met " + name}
				labelPosition="left"
				icon="file"
				onClick={() => fileInputRef.current && fileInputRef.current.click()}
				disabled={disabled}
			/>
			<input
				ref={fileInputRef}
				type="file"
				hidden
				onChange={handleFileChange}
				disabled={disabled}
			/>
			{file && (
				<Label pointing="left" size="large">
					{file.name}
				</Label>
			)}
			<br />
			{message}
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					className={styles.importButton}
					compact
					content="Importeer"
					labelPosition="left"
					icon="upload"
					disabled={!file || disabled}
					onClick={() => onSubmit(file)}
				/>
			</div>
		</Segment>
	);

	return noHeader ? (
		content
	) : (
		<Segment.Group className={styles.segmentGroup}>
			<Segment className={styles.header}>{name} importeren</Segment>
			{content}
		</Segment.Group>
	);
};

export default FileImportFormUncontrolled;
